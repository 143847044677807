<div class="row" style="margin-top: 30px;">
  <div class="col-sm-2"></div>
  <div class="col-sm-4">
    <div class="card text-white bg-info mb-3">
      <div class="card-body text-center">
        <a href="/licence" class="btn btn-info h2">LICENCES</a>
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="card text-white bg-success mb-3">
      <div class="card-body text-center">
        <a href="/master" class="btn btn-success h2">MASTERS</a>
      </div>
    </div>
  </div>
  <div class="col-sm-2"></div>
</div>

<div class="row" style="margin-top: 10px;">
  <div class="col-sm-2"></div>
  <div class="col-sm-4">
    <div class="card text-white bg-danger mb-3">
      <div class="card-body text-center">
        <a href="/doctorat" class="btn btn-danger h2">DOCTORAT</a>
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="card  text-white mb-3" style="background-color: #ee189c;">
      <div class="card-body text-center">
        <a href="/actualites" class="btn h2" style="background-color: #ee189c;">ACTUALITES</a>
      </div>
    </div>
  </div>

  <div class="col-sm-2"></div>
</div>

